<template>
  <v-card class="pa-3" id="add-categories">
    <v-card-text>
      <v-form
        ref="addFormData"
        @submit.prevent="handleFormSubmit"
        class="multi-col-validation"
      >
        <v-row>
          <v-col md="12" cols="12">
            <v-sheet class="px-1">
              <v-chip-group multiple>
                <v-chip
                  class="v-chip-light-bg secondary--text mr-2 my-2"
                  color="secondary"
                  v-for="(skill, index) in skills"
                  :key="index"
                >
                  {{ skill.name }}
                </v-chip>
              </v-chip-group>
            </v-sheet>
          </v-col>

          <v-col md="12" cols="12">
            <small-notify class="mb-6">
              <template #default>
                Añade todas las actividades o rubros de tu interes, esto te
                ayudara a encontrar las ofertas que mejor se adapten a tu perfil
              </template>
            </small-notify>

            <SelectSkills
              label="Añadir actividad o rubro"
              placeholder="Ej: Pintura, Construcción, Plomeria"
              v-model="addFormData.tag"
              :rules="[validators.required]"
              class=""
            />

            <v-btn
              rounded
              color="primary"
              :loading="loading"
              :disabled="loading"
              type="submit"
              class="float-right mt-4"
            >
              Añadir a mi perfil
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  ref,
  reactive,
  getCurrentInstance,
  onMounted,
  computed,
} from "@vue/composition-api";

import FormTextField from "@/components/forms/TextField";
import SelectSkills from "@/components/general/SelectSkills";
import SmallNotify from "@/components/general/SmallNotify";

import { required } from "@core/utils/validation";
import api from "@/services";
import { useRouter } from "@core/utils";

export default {
  components: {
    FormTextField,
    SelectSkills,
    SmallNotify,
  },
  setup(props) {
    const vm = getCurrentInstance().proxy;
    const { route } = useRouter();

    const loading = ref(false);
    const skills = ref([]);

    const addFormData = reactive({
      tag: null,
    });

    const showInfoSkills = computed(() => {
      return !skills.value.length || route.value?.hash == "#add-categories";
    });

    const onToggleLoading = () => {
      loading.value = !loading.value;
    };

    const handleFormSubmit = async () => {
      if (!addFormData.tag) return;

      onToggleLoading();

      try {
        const { data: response } = await api.addWorkersSkills(addFormData);
        const { data } = response;
        if (data) {
          skills.value.unshift(data);
          vm.$alert("Actividad añadida correctamente", null, "success");
        }
      } catch (error) {
        vm.$alert(
          "Ocurrio un error al guardar su actividad, intente nuevamente",
          null,
          "error"
        );
      }

      onToggleLoading();
    };

    const getWorkersSkills = async () => {
      try {
        const { data: response } = await api.getWorkersSkills();
        const { data } = response;
        skills.value = data;
      } catch (error) {}
    };

    onMounted(() => {
      getWorkersSkills();
    });

    return {
      skills,
      addFormData,
      loading,
      showInfoSkills,

      validators: {
        required,
      },

      onToggleLoading,
      handleFormSubmit,
    };
  },
};
</script>

<style></style>
