<template>
  <p>
    <small
      ><v-icon>{{ icons.mdiInformation }}</v-icon> <slot
    /></small>
  </p>
</template>

<script>
import { mdiInformation } from "@mdi/js";

export default {
  setup(props) {
    return {
      icons: {
        mdiInformation,
      },
    };
  },
};
</script>

<style></style>
