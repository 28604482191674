<template>
  <v-container v-if="user" class="mt-16">
    <v-tabs
      class="my-4 wrapper-tabs"
      hide-slider
      style="box-shadow: none !important"
    >
      <v-tab :href="`#categories`">Actividades o Rubros</v-tab>
      <v-tab :href="`#profile`">Perfil</v-tab>

      <v-tab-item value="categories">
        <WorkerSkills />
      </v-tab-item>
      <v-tab-item value="profile">
        <v-card class="pa-3 mt-2">
          <v-card-text>
            <v-form
              ref="addFormData"
              @submit.prevent="handleFormSubmit"
              class="multi-col-validation"
            >
              <v-row>
                <v-col md="6" cols="12">
                  <FormTextField
                    label="Nombre de la empresa"
                    :rules="[validators.required]"
                    :value="user.company"
                    @onChangeInput="(v) => onChangeFormInputs(v, 'company')"
                    class="my-2"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <FormTextField
                    label="Persona de contacto"
                    :rules="[validators.required]"
                    :value="user.name"
                    @onChangeInput="(v) => onChangeFormInputs(v, 'name')"
                    class="my-2"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <FormTextField
                    label="Dirección"
                    :rules="[validators.required]"
                    :value="user.address"
                    @onChangeInput="(v) => onChangeFormInputs(v, 'address')"
                    class="my-2"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <SelectLocationComunes
                    v-model="user.location_id"
                    :rules="[]"
                    class="my-2"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <SelectLocationCities
                    v-model="user.city_id"
                    :rules="[]"
                    class="my-2"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <FormTextField
                    label="Correo electrónico"
                    :rules="[validators.required, validators.emailValidator]"
                    :value="user.email"
                    @onChangeInput="(v) => onChangeFormInputs(v, 'email')"
                    class="my-2"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <FormTextField
                    label="RUT"
                    :rules="[validators.required]"
                    :value="user.rut"
                    @onChangeInput="(v) => onChangeFormInputs(v, 'rut')"
                    class="my-2"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <FormTextField
                    label="Teléfono"
                    :rules="[validators.required]"
                    :value="user.phone"
                    @onChangeInput="(v) => onChangeFormInputs(v, 'phone')"
                    class="my-2"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <FormTextField
                    label="Años de la empresa"
                    :value="user.years_bussines"
                    @onChangeInput="
                      (v) => onChangeFormInputs(v, 'years_bussines')
                    "
                    class="my-2"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <FormTextField
                    label="Whatsapp"
                    :value="user.wattsapp"
                    @onChangeInput="(v) => onChangeFormInputs(v, 'wattsapp')"
                    class="my-2"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <FormTextField
                    label="Instagram"
                    :value="user.instagram"
                    @onChangeInput="(v) => onChangeFormInputs(v, 'instagram')"
                    class="my-2"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <FormTextField
                    label="Facebook"
                    :value="user.facebook"
                    @onChangeInput="(v) => onChangeFormInputs(v, 'facebook')"
                    class="my-2"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <FormTextField
                    label="Linkedin"
                    :value="user.linkedin"
                    @onChangeInput="(v) => onChangeFormInputs(v, 'linkedin')"
                    class="my-2"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <FormTextField
                    label="Sitio web"
                    :value="user.web"
                    @onChangeInput="(v) => onChangeFormInputs(v, 'web')"
                    class="my-2"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <FormTextFieldPassword
                    :value="user.password"
                    label="Contraseña"
                    placeholder="********"
                    :rules="[]"
                    @onChangeInput="(v) => onChangeFormInputs(v, 'password')"
                    class="my-2"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <FormTextFieldPassword
                    :value="user.confirm_password"
                    label="Repetir contraseña"
                    placeholder="********"
                    :rules="[
                      validators.confirmedValidator(
                        user.password,
                        user.confirm_password
                      ),
                    ]"
                    @onChangeInput="
                      (v) => onChangeFormInputs(v, 'confirm_password')
                    "
                    class="my-2"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    :loading="loading"
                    :disabled="loading"
                    rounded
                    color="primary"
                    type="submit"
                    class="float-right mt-10"
                  >
                    Actualizar perfil
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import { ref, reactive, getCurrentInstance } from "@vue/composition-api";
import FormTextField from "@/components/forms/TextField";
import FormTextFieldPassword from "@/components/forms/TextFieldPassword";
import WorkerSkills from "@/components/worker/skills/Skill";
import SelectLocationCities from "@/components/general/SelectLocationCities";
import SelectLocationComunes from "@/components/general/SelectLocationComunes";
import {
  required,
  emailValidator,
  integerValidator,
  confirmedValidator,
} from "@core/utils/validation";
import api from "@/services";

export default {
  name: "WorkerAccount",
  components: {
    FormTextField,
    FormTextFieldPassword,
    WorkerSkills,
    SelectLocationCities,
    SelectLocationComunes,
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const userData = JSON.parse(localStorage.getItem("userData"));
    const user = reactive(userData);
    const loading = ref(false);

    const onChangeFormInputs = (value, property) => {
      user[property] = value;
    };

    const onToggleLoading = () => {
      loading.value = !loading.value;
    };

    const handleFormSubmit = async () => {
      const refsForm = vm.$refs.addFormData;

      if (!refsForm.validate()) return;

      onToggleLoading();

      try {
        await api.profileUpdate(user);
        vm.$alert("Perfil actualizada correctamente", null, "success");
        localStorage.setItem("userData", JSON.stringify({ ...user }));
      } catch (error) {
        // const { data } = error.response;
        const message =
          "Ocurrio un error al actualizar su cuenta, intente nuevamente";
        vm.$alert(message, null, "error");
      }

      onToggleLoading();
    };

    return {
      user,
      loading,

      validators: {
        required,
        emailValidator,
        integerValidator,
        confirmedValidator,
      },

      onChangeFormInputs,
      handleFormSubmit,
    };
  },
};
</script>

<style></style>
